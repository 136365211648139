import React from 'react';

import _ from 'lodash';
import moment from 'moment';
import qs from 'query-string';
import uuidv4 from 'uuid/v4';
import $ from 'jquery';
import tinycolor from 'tinycolor2';

import { displayRulesTemplates } from '../settings';

const bs58 = require('bs58');
const numeral = require('numeral');

export function uuid() {
  return uuidv4().replace(/-/g, '');
}

export function encode(str) {
  try {
    return bs58.encode(Buffer.from(str, 'hex'));
  } catch(err) {
    return str;
  }
};

export function decode(str) {
  try {
    return bs58.decode(str).toString('hex');
  } catch(err) {
    return str;
  }
}

/* User -> Accounts can have two formats */
export function getAccountId(account) {
  if (typeof account === 'object') {
    return account._id;
  }

  return account;
}

export function parseUrl(router) {
  if (!router || !router.location || !router.location.pathname) { return []; }

  const pathname = router.location.pathname
    .replace('/dashboard', '')
    .replace('/reports', '')
    .replace('/responses', '')
    .replace('/emails', '')
    .replace('/votes', '')
    .replace('/automations', '')
    .replace('/activity', '')
    .replace('/api', '')
    .replace('/insights', '')
    .replace('/campaigns', '')
    .replace('/charts', '')
    .replace('/participants', '')
    .replace('/integrations', '');
  const path = pathname.split('/').slice(1);
  const results = [];

  for (let i = 0; i < path.length; i+=2) {
    results.push(decode(path[i+1]));
  }

  return results;
}

export function move(arr, old_index, new_index) {
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing purposes
};


export function renderNumber(num, placeholder = 0) {
  if (num === undefined) { return placeholder; }

  return numeral(num).format('0.[0]a', Math.floor);
}

export function renderCurrency(num, placeholder = '0', currency = '$', format = `0,0.00[a]`) {
  if (window.userCurrency) {
    currency = window.userCurrency;
  }
  if (num === undefined) { return placeholder; }

  return currency + numeral(num).format(format);
}

export function numberWithCommas(x = '') {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const slideTypes = [
  { label: 'Multi Choice', value: 'checkbox', icon: 'fa-check-square', type: 'fixed-choice' },
  { label: 'Single Choice', value: 'question', icon: 'fa-check-circle', type: 'fixed-choice' },
  { label: 'Email Capture', value: 'email-capture', icon: 'fa-envelope', type: 'open-ended' },
  { label: 'Binary', value: 'binary', icon: 'fa-spell-check', type: 'fixed-choice'  },
  { label: 'Range', value: 'range', icon: 'fa-sort-amount-up', type: 'fixed-choice' },
  { label: 'Star Rating', value: 'star-rating', icon: 'fa-star', type: 'fixed-choice' },
  { label: 'Satisfaction', value: 'satisfaction', icon: 'fa-theater-masks', type: 'fixed-choice' },
  { label: 'Short Answer', value: 'short-answer', icon: 'fa-comment', type: 'open-ended' },
  { label: 'Long Answer', value: 'long-answer', icon: 'fa-comment-alt', type: 'open-ended' },
  { label: 'Form', value: 'form', icon: 'fa-file-alt', type: 'open-ended' },
  { label: 'Consent', value: 'legal-consent', icon: 'fa-balance-scale', type: 'fixed-choice' },
  { label: 'Dropdown', value: 'dropdown', icon: 'fa-caret-square-down', type: 'fixed-choice' },
  { label: 'Mosaic', value: 'inline-multiple-choice', icon: 'fa-th-large', type: 'fixed-choice' },
  { label: 'Actions', value: 'action', icon: 'fa-directions', type: 'passive' },
  { label: 'Date', value: 'date', icon: 'fa-calendar-day', type: 'open-ended' },
  { label: 'Country', value: 'country', icon: 'fa-earth-americas', type: 'fixed-choice' },
  { label: 'Ranked Choice', value: 'rank', icon: 'fa-ranking-star', type: 'fixed-choice' },
  { label: 'Slider', value: 'slider', icon: 'fa-sliders', type: 'fixed-choice' },
  // { label: 'Calendar', value: 'calendar', icon: 'fa-calendar' },
  // { label: 'Matrix', value: 'matrix', icon: 'fa-table-cells' },
  // { label: 'Address', value: 'address', icon: 'fa-address' },
  { label: 'Image Choice', value: 'image-choice', icon: 'fa-image', type: 'fixed-choice' },
  { label: 'File Upload', value: 'file-upload', icon: 'fa-cloud-arrow-up', type: 'open-ended' },
  { label: 'Copy', value: 'copy', icon: 'fa-quote-left', type: 'passive' },
  { label: 'Reward', value: 'reward', icon: 'fa-gift', type: 'passive' },
];

export function getSlideLabel(type = 'question') {
  const { label } = _.find(slideTypes, ({ value }) => value === type );
  return label;
}

export function getSlideIcon(type = 'question') {
  const { icon } = _.find(slideTypes, ({ value }) => value === type );
  return icon;
}

export const previewSlides = {
  'reward': {
    type: 'reward',
    title: 'Thanks for your feedback!',
    subtitle: "We really appreciate your opinion.",
    copy: 'Use the discount code below for <strong>10% off</strong> your next order!',
    rewardCode: 'ZIGPOLL10',
    settings: { showTitle: true, disableSentimentAnalysis: true },
  },
  'long-answer': {
    type: 'long-answer',
    title: 'Long Answer Example',
    subtitle: "We're curious...",
    copy: 'What do you really think about our new collection?',
    settings: { showTitle: true, disableSentimentAnalysis: true },
    hasResponses: true,
    hasPlaceholder: true,
  },
  'long-answer': {
    type: 'long-answer',
    title: "We're curious...",
    subtitle: "What do you like most about our brand?",
    copy: '',
    settings: { showTitle: true, disableSentimentAnalysis: true },
    hasResponses: true,
    hasPlaceholder: true,
  },
  'short-answer': {
    type: 'short-answer',
    title: 'Quick Question',
    subtitle: "How did you find us?",
    copy: '',
    settings: { showTitle: true, disableSentimentAnalysis: true },
    hasResponses: true,
    hasPlaceholder: true,
  },
  'copy': {
    type: 'copy',
    title: 'Got a second?',
    subtitle: "We have a couple questions for you.",
    copy: "In exchange for your feedback, we'll give you a discount code for <strong>10% Off</strong> your next order.",
    settings: { showTitle: true, disableSentimentAnalysis: true, showResults: false }
  },
  'question': {
    type: 'question',
    title: 'What color do you prefer?',
    subtitle: "This will help us plan our fall product line.",
    copy: "",
    answers: [
      { title: 'Red', handle: 'red' },
      { title: 'Green', handle: 'green' },
      { title: 'Blue', handle: 'blue' }
    ],
    settings: { showTitle: true, disableSentimentAnalysis: true, showResults: false },
    hasResponses: true
  },
  'checkbox': {
    type: 'checkbox',
    title: 'What colors do you prefer?',
    subtitle: "Select as many as you like.",
    copy: '',
    answers: [
      { title: 'Red', handle: 'red' },
      { title: 'Green', handle: 'green' },
      { title: 'Blue', handle: 'blue' },
      { title: 'Orange', handle: 'orange' }
    ],
    minimumResponses: 1,
    hasResponses: true,
    settings: { showTitle: true, disableSentimentAnalysis: true, showResults: false }
  },
  'email-capture': {
    type: 'email-capture',
    title: 'Stay in touch!',
    subtitle: "Enter your email for the latest and greatest updates from our store.",
    copy: '',
    settings: { showTitle: true, disableSentimentAnalysis: true },
    hasPlaceholder: true,
  },
  'range': {
    type: 'range',
    title: 'We just launched our new version!',
    subtitle: "How do you think it performs on a scale from 1 to 5.",
    copy: '',
    answers: [
      { title: '1', handle: '1' },
      { title: '2', handle: '2' },
      { title: '3', handle: '3' },
      { title: '4', handle: '4' },
      { title: '5', handle: '5' }
    ],
    leftLabel: 'Not Satisfied',
    rightLabel: 'Very Satisfied',
    settings: { showTitle: true }
  },
  'satisfaction': {
    type: 'satisfaction',
    title: "Well it's officially live!",
    subtitle: "How satisfied are you with the redesign?",
    copy: '',
    answers: [
      { title: 'Very Unsatisfied', handle: 'very-unsatisfied' },
      { title: 'Unsatisfied', handle: 'unsatisfied' },
      { title: 'Neutral', handle: 'neutral' },
      { title: 'Satisfied', handle: 'satisfied' },
      { title: 'Very Satisfied', handle: 'very-satisfied' }
    ],
    leftLabel: 'Not Satisfied',
    rightLabel: 'Very Satisfied',
    settings: { showTitle: true }
  },
  'star-rating': {
    type: 'star-rating',
    title: "How would you rate our new redesign?",
    subtitle: "",
    copy: '',
    answers: [
      { title: '1 Star', handle: '1-star' },
      { title: '2 Stars', handle: '2-stars' },
      { title: '3 Stars', handle: '3-stars' },
      { title: '4 Stars', handle: '4-stars' },
      { title: '5 Stars', handle: '5-stars' }
    ],
    leftLabel: "Much worse.",
    rightLabel: 'Way better!',
    settings: { showTitle: true }
  },
  'binary': {
    type: 'binary',
    title: "Well it's officially live!",
    subtitle: "Do you like our redesign?",
    copy: '',
    answers: [
      { title: 'Yes', handle: 'yes' },
      { title: 'No', handle: 'no' }
    ],
    settings: { showTitle: true }
  },
  'form': {
    type: 'form',
    title: "Contact Us",
    subtitle: "Fill out the form below to send us a note.",
    copy: 'If you want us to get back to you, please include your email.',
    formInputs: [
      {label: "Question/Comment", type: "long-answer", optional: false, options: []},
      {label: "Email", type: "email-capture", optional: true, options: []}
    ],
    settings: { showTitle: true, disableSentimentAnalysis: true },
    hasResponses: true,
  },
  'legal-consent': {
    type: 'legal-consent',
    answers: [
      { title: '✔', handle: 'checked', label: 'I have read and agree to the terms and conditions.' }
    ],
    title: 'Please check the box below to confirm you have read our <a target="_blank" href="https://www.zigpoll.com/terms-and-policies">terms & conditions</a>.',
    settings: { showTitle: true, disableSentimentAnalysis: true },
  },
  'dropdown': {
    type: 'dropdown',
    answers: [
      { title: 'Red', handle: 'red' },
      { title: 'Green', handle: 'green' },
      { title: 'Blue', handle: 'blue' },
      { title: 'Orange', handle: 'orange' }
    ],
    title: 'What color do you prefer?',
    hasResponses: true,
    settings: { showTitle: true, disableSentimentAnalysis: true },
  },
  'inline-multiple-choice': {
    type: 'inline-multiple-choice',
    answers: [
      { title: 'Red', handle: 'red' },
      { title: 'Green', handle: 'green' },
      { title: 'Blue', handle: 'blue' },
      { title: 'Orange', handle: 'orange' },
      { title: 'Pink', handle: 'pink' },
    ],
    title: "What's your favorite color(s)?",
    copy: "",
    hasResponses: true,
    settings: { showTitle: true, disableSentimentAnalysis: true },
  },
  'action': {
    type: 'action',
    actions: [
      { title: 'Follow us on Twitter', color: "#1DA1F2", icon: 'fab fa-twitter', link: 'https://www.twitter.com' },
      { title: 'Follow us on Facebook', color: "#3b5998", icon: 'fab fa-facebook', link: 'https://www.facebook.com' }
    ],
    title: 'Click the below to follow us on social!',
    settings: { showTitle: true, disableSentimentAnalysis: true },
  },
  'date': {
    type: 'date',
    title: "Enter your birthday and we will send you a surprise!",
    copy: '',
    settings: { showTitle: true, disableSentimentAnalysis: true },
    hasResponses: true,
  },
  'country': {
    type: 'country',
    title: "What country are you from?",
    copy: '',
    answers: [],
    settings: { showTitle: true, disableSentimentAnalysis: true },
  },
  'calendar': {
    type: 'calendar',
    title: "Book a time on our calendar.",
    copy: '',
    settings: { showTitle: true, disableSentimentAnalysis: true },
  },
  // 'matrix': {
  //   type: 'matrix',
  //   title: "Book a time on our calendar.",
  //   copy: 'We look forward to chatting then!',
  //   settings: { showTitle: true, disableSentimentAnalysis: true },
  // },
  'rank': {
    type: 'rank',
    answers: [
      { title: 'Red', handle: 'red' },
      { title: 'Green', handle: 'green' },
      { title: 'Blue', handle: 'blue' },
      { title: 'Orange', handle: 'orange' },
      { title: 'Pink', handle: 'pink' },
    ],
    title: "Please rank your favorite colors",
    copy: "",
    hasResponses: false,
    settings: { showTitle: true, disableSentimentAnalysis: true },
  },
  'image-choice': {
    type: 'image-choice',
    answers: [
      { url: 'http://via.placeholder.com/400x450', handle: 'red', title: "Red" },
      { url: 'http://via.placeholder.com/400x410', handle: 'green', title: "Green" },
      { url: 'http://via.placeholder.com/400x420', handle: 'blue', title: "Blue" },
      { url: 'http://via.placeholder.com/400x440', handle: 'orange', title: "Orange" },
    ],
    title: "What's your favorite image below?",
    copy: "",
    hasResponses: true,
    settings: { showTitle: true, disableSentimentAnalysis: true },
  },
  'file-upload': {
    type: 'file-upload',
    title: "Please upload your id card.",
    copy: "",
    settings: { showTitle: true, disableSentimentAnalysis: true },
    hasResponses: true,
    hasPlaceholder: true,
  },
  'slider': {
    type: 'slider',
    title: "How much would you be willing to pay for this item?",
    copy: "",
    answers: [],
    hasResponses: false,
    settings: { showTitle: true, disableSentimentAnalysis: true, range: [ 0, 100 ], unit: 5 },
  },
};

export function handleize(str, slideId) {
  if (!str) { return ''; }

  if (!slideId) {
    return str.replace(/\s+/g, '-').replace(/\./g, "").toLowerCase();
  }

  if (getTimestamp(slideId).getTime() >= 1731340760520) {
    return str.replace(/\s+/g, '-').replace(/\./g, "").toLowerCase();
  } else {
    return str.replace(/\s+/g, '-').toLowerCase();
  }
}

export function getActiveCard(user) {
  if (!user) { return }
  if (!user.customer) { return }

  const customer = user.customer;
  const { default_source } = customer;
  const sources = customer.sources;

  if (!sources) { return; }
  if (!default_source) { return; }

  const data = sources.data;

  if (!data) { return; }
  if (data.length === 0) { return; }

  const activeCard = _.find(data, ({ id }) => id === default_source);

  return activeCard;
}

export function getSubscriptionCard(user, subscription) {
  if (!user) { return }
  if (!user.customer) { return }
  if (!subscription) { return }
  if (!subscription.default_source) { return }

  const customer = user.customer;
  const sources = customer.sources;

  if (!sources) { return; }
  const data = sources.data;

  if (!data) { return; }
  if (data.length === 0) { return; }

  const activeCard = _.find(data, ({ id }) => id === subscription.default_source);

  return activeCard;
}

export function getCards(user) {
  if (!user) { return }
  if (!user.customer) { return }

  const customer = user.customer;
  const { sources } = customer;

  if (!sources) { return; }

  const data = sources.data;

  if (!data) { return; }
  if (data.length === 0) { return; }

  return data;
}

export function getSubscription(user, subscriptionId) {
  if (!user) { return }
  if (!user.customer) { return }

  const customer = user.customer;
  const { subscriptions } = customer;

  if (!subscriptions) { return; }

  const data = subscriptions.data;
  if (!data || !data.length) { return; }

  const match = _.find(data, (sub) => sub.id === subscriptionId);
  return match;
}

export function getTimestamp(_id) {
  if (!_id) { return; }
  return new Date(parseInt(_id.toString().slice(0,8), 16) * 1000);
}

export function pluralize(num, str) {
  if (num === 1) {
    return str;
  }
  return str + 's';
}

export function getDate(_id) {
  if (!_id) { return; }
  let date = getTimestamp(_id);
  if (!date) { return; }
  return moment(date).fromNow();
}

export function getDateAsTimestamp(_id, format='MMMM Do YYYY, h:mm:ss A') {
  if (!_id) { return; }
  let date = getTimestamp(_id);
  if (!date) { return; }
  return moment(date).format(format);
}

export function getDateFromUpdatedAt(date) {
  if (!date) { return; }
  return moment(date).fromNow();
}

export function generateFilename(base, fileType = 'csv') {
  const date = new Date();
  const formattedDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

  return `${base}_${formattedDate}.${fileType}`;
}

export function renderParticipantId(_id, len = 20, ignorePrefix) {
  if (!_id) { return '' }
  _id = ''+_id;

  if (ignorePrefix) {
    return `${_id.slice(0, len)}${len >= _id.length ? '' : '...'}`;
  }

  return `Participant: ${_id.slice(0, len)}${len >= _id.length ? '' : '...'}`;
}

export function renderAccountTitle(title, len = 20) {
  if (!title) { return ''; }
  return `${title.slice(0, len)}${len >= title.length ? '' : '...'}`;
}

export function truncate(str, len = 20) {
  if (!str) { return ''; }
  if (!str.slice) { return ''; }
  return `${str.slice(0, len)}${len >= str.length ? '' : '...'}`;
}

export function renderText(text, len = 8) {
  if (!text) { return '' }
  return `${text.slice(0, len)}${len >= text.length ? '' : '...'}`;
}

export function getTab(search, defaultTab) {
  const { tab } = qs.parse(search);
  return tab || defaultTab;
}

export function getParams(search) {
  return qs.parse(search);
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function scrollToTop() {
  $('#foreground').scrollTop(0);
  $(window).scrollTop(0);
  return true;
}

export function getSentimentIcon(sentiment) {
  let title = "Neutral";
  let icon = 'fa-meh'
  let color = ''

  if (sentiment > 0)  {
    icon = 'fa-smile';
    title = 'Positive';
    color = 'green';
  }

  if (sentiment < 0) {
    icon = 'fa-frown';
    title = 'Negative';
    color = 'red';
  }

  return <i title={title} className={`${color} fa ${icon}`}/>
}

export function getSentimentClass(sentiment) {
  let className = "neutral";

  if (sentiment > 0)  {
    className = 'positive';
  }

  if (sentiment < 0) {
    className = 'negative';
  }

  return className;
}

export function getSentimentTitle(sentiment) {
  let className = "Neutral";

  if (sentiment > 0)  {
    className = 'Positive';
  }

  if (sentiment < 0) {
    className = 'Negative';
  }

  return className;
}

export function validateEmail(email) {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function getRangeFontSize(len) {
  if (len > 8) {
    return 15;
  }
  if (len > 6) {
    return 17;
  }
  return 18;
}

export function getBoxRatio(len, containerWidth = 410, isStar) {
  let perc = 30;
  let limit = 50;
  if (len > 5) {
    perc = 100;
    // return '100%';
  } else if (len > 4) {
    perc = 80;
    // return '80%';
  } else if (len > 3) {
    perc = 60;
    // return '60%';
  } else if (len > 2) {
    perc = 45;
    // return '45%';
  }
  if (isStar) {
    perc = 100;
    limit = 50;
  }

  // console.log(((containerWidth / len) * (perc / 100)));
  if ( ((containerWidth / len) * (perc/100)) > limit) {
    return limit;
  }
  // return '30%';
  return perc+'%';
}

export function getRangeMaxWidth(len) {
  if (len > 9) {
    return 600;
  }
  if (len > 8) {
    return 500;
  }
  if (len > 7) {
    return 450;
  }
  if (len > 6) {
    return 400;
  }
  if (len > 5) {
    return 375;
  }
  return 350;
}

export function getDisplaySettings(account, poll) {
  let displaySettings = account.displaySettings || {};

  if (poll && poll.displaySettings) {
    displaySettings = poll.displaySettings;
  }

  return displaySettings;
}

export function getVisibilitySettings(account, poll) {
  let visibilitySettings = account.visibilitySettings || {};

  if (poll && poll.visibilitySettings) {
    visibilitySettings = poll.visibilitySettings;
  }

  return visibilitySettings;
}

export function getTargetingSettings(account, poll) {
  let targetingSettings = account.targetingSettings || {};

  if (poll && poll.targetingSettings) {
    targetingSettings = poll.targetingSettings;
  }

  return targetingSettings;
}

export function getTemplateFromDisplayRules(pr = [], per = [], returnFull) {
  let match =  _.find(displayRulesTemplates, ({ pageRules, pageExcludeRules, createPollOnly, settings }) => {
    if (createPollOnly) { return false; }
    if (settings && settings.apiOnly) { return false; }

    if (_.isEqual(pr, ["/(.*)/products/:variable", "/products/:variable"])) {
      pr = ["/(.*)/products/:variable", "/products/(.*)"];
    }
    return _.isEqual(pr, pageRules) && _.isEqual(per, pageExcludeRules);
  });

  if (!match && (pr.indexOf('/(.*)/thank_you') !== -1)) {
    match = _.find(displayRulesTemplates, ({ handle }) => handle === 'post-purchase-only');
  }

  if (match) {
    if (returnFull) {
      return match;
    }
    return match.handle;
  }
}

export function needsCheckoutExtension(poll) {
  if (!poll) { return false; }
  if (!poll.pageRules) { return false; }
  if (poll.settings && poll.settings.apiOnly) { return false; }

  let needCheckoutExtension = false;

  poll.pageRules.forEach((rule) => {
    if (rule === '/(.*)/thank_you') {
      needCheckoutExtension = true;
    }
    if (rule === '/:order_number/orders/:variable') {
      needCheckoutExtension = true;
    }
    if (rule === '/account') {
      needCheckoutExtension = true;
    }
    if (rule === '/account') {
      needCheckoutExtension = true;
    }
    if (rule === '/account/(.*)') {
      needCheckoutExtension = true;
    }
  });

  return needCheckoutExtension;
}


export function prepareShopifyCustomerTargeting(targeting) {
  if (!targeting) { return [[],[]]; }

  let output = [[],[]];
  if (typeof targeting === 'string') {
    if (targeting === 'new-customers') {
      output[0].push(['orders_count', 'lt', 2]);
    }
    if (targeting === 'existing-customers') {
      output[0].push(['orders_count', 'gt', 1]);
    }
  } else {
    output = targeting;
  }

  return output;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const customerTargetingOptions = [
{ 
  handle: '',
  label: 'All Customers',
  info: 'This survey will show for both new and returning customers.',
  value: [[],[]],
},
{ 
  handle: 'new-customers',
  label: 'New Customers',
  info: 'This survey will show for new customers only.',
  value: [[
    ['orders_count', 'lt', 2]
  ],[]],
  requireCustomer: true
},
{ 
  handle: 'existing-customers',
  label: 'Returning Customers',
  info: 'This survey will show for returning customers only.',
  value: [[
    ['orders_count', 'gt', 1]
  ], []],
  requireCustomer: true
}
];

export const shopifyCustomerTargetingOptions = [
  // {
  //   value: 'created_at',
  //   name: 'Created At',
  //   operators: [
  //     { value: 'lt', name: 'Before' },
  //     { value: 'gt', name: 'After' },
  //   ],
  //   inputType: 'date'
  // },
  {
    value: 'product_ids',
    name: 'Product Ids',
    operators: [
      { value: 'includes', name: 'Includes All' },
      { value: 'includes_any', name: 'Includes Any' },
      { value: 'excludes', name: 'Does Not Include All' },
      { value: 'excludes_any', name: 'Does Not Include Any' },
    ],
    inputType: 'tags',
    placeholder: 'Add Product Ids...',
    requireOrder: true
  },
  {
    value: 'variant_ids',
    name: 'Variant Ids',
    operators: [
      { value: 'includes', name: 'Includes All' },
      { value: 'includes_any', name: 'Includes Any' },
      { value: 'excludes', name: 'Does Not Include All' },
      { value: 'excludes_any', name: 'Does Not Include Any' },
    ],
    inputType: 'tags',
    placeholder: 'Add Variant Ids...',
    requireOrder: true
  },
  {
    value: 'collection_ids',
    name: 'Collection Ids',
    operators: [
      { value: 'includes', name: 'Includes All' },
      { value: 'includes_any', name: 'Includes Any' },
      { value: 'excludes', name: 'Does Not Include All' },
      { value: 'excludes_any', name: 'Does Not Include Any' },
    ],
    inputType: 'tags',
    placeholder: 'Add Collection Ids...',
    requireOrder: true
  },
  {
    value: 'product_tags',
    name: 'Product Tags',
    operators: [
      { value: 'includes', name: 'Includes All' },
      { value: 'includes_any', name: 'Includes Any' },
      { value: 'excludes', name: 'Does Not Include All' },
      { value: 'excludes_any', name: 'Does Not Include Any' },
    ],
    inputType: 'tags',
    placeholder: 'Add Product Tags...',
    requireOrder: true
  },
  {
    value: 'product_types',
    name: 'Product Type',
    operators: [
      { value: 'includes', name: 'Includes All' },
      { value: 'includes_any', name: 'Includes Any' },
      { value: 'excludes', name: 'Does Not Include All' },
      { value: 'excludes_any', name: 'Does Not Include Any' },
    ],
    inputType: 'tags',
    placeholder: 'Add Product Types...',
    requireOrder: true
  },
  {
    value: 'discount_codes',
    name: 'Discount Code',
    operators: [
      { value: 'includes', name: 'Includes All' },
      { value: 'includes_any', name: 'Includes Any' },
      { value: 'excludes', name: 'Does Not Include All' },
      { value: 'excludes_any', name: 'Does Not Include Any' },
      { value: 'exists', name: 'Exists' },
      { value: 'does_not_exist', name: 'Does Not Exist' },
    ],
    inputType: 'tags',
    placeholder: 'Add Product Types...',
    requireOrder: true
  },
  {
    value: 'orders_count',
    name: 'Orders Count',
    operators: [
      { value: 'lt', name: 'Less than' },
      { value: 'gt', name: 'Greater than' },
      { value: 'eq', name: 'Equals' },
    ],
    inputType: 'number',
    requireCustomer: true
  },
  {
    value: 'tags',
    name: 'Customer Tags',
    operators: [
      { value: 'includes', name: 'Includes All' },
      { value: 'includes_any', name: 'Includes Any' },
      { value: 'excludes', name: 'Does Not Include All' },
      { value: 'excludes_any', name: 'Does Not Include Any' },
    ],
    inputType: 'tags',
    requireCustomer: true
  },
  {
    value: 'total_spent',
    name: 'Customer Total Spent',
    operators: [
      { value: 'lt', name: 'Less than' },
      { value: 'gt', name: 'Greater than' },
      { value: 'eq', name: 'Equals' }
    ],
    inputType: 'number',
    requireCustomer: true
  },
  {
    value: 'total_price',
    name: 'Order Total',
    operators: [
      { value: 'lt', name: 'Less than' },
      { value: 'gt', name: 'Greater than' },
      { value: 'eq', name: 'Equals' },
    ],
    inputType: 'number',
    requireOrder: true
  },
  {
    value: 'source_name',
    name: 'Order Source',
    operators: [
      { value: 'includes', name: 'Includes' },
      { value: 'excludes', name: 'Excludes' }
    ],
    inputType: 'tags',
    placeholder: 'Add Order Source...',
    requireOrder: true
  },
  {
    value: 'location_id',
    name: 'Order Location Id',
    operators: [
      { value: 'includes', name: 'Includes All' },
      { value: 'includes_any', name: 'Includes Any' },
      { value: 'excludes', name: 'Does Not Include' },
      { value: 'excludes_any', name: 'Does Not Include Any' },
    ],
    inputType: 'tags',
    placeholder: 'Add Order Location Id...',
    requireOrder: true
  },
  {
    value: 'order_tags',
    name: 'Order Tags',
    operators: [
      { value: 'includes', name: 'Includes All' },
      { value: 'includes_any', name: 'Includes Any' },
      { value: 'excludes', name: 'Does Not Include' },
      { value: 'excludes_any', name: 'Does Not Include Any' },
    ],
    inputType: 'tags',
    requireOrder: true
  },
  // {
  //   value: 'last_order_date',
  //   name: 'Last Order Date',
  //   operators: [
  //     { value: 'lte', name: 'Less then or equal' },
  //     { value: 'lt', name: 'Less than' },
  //     { value: 'gt', name: 'Greater than' },
  //     { value: 'gte', name: 'Greater than or equal to' },
  //   ],
  //   inputType: 'date'
  // }
]

export const shopifyCustomerTargetingEmailOptions = [
  // {
  //   value: 'created_at',
  //   name: 'Created At',
  //   operators: [
  //     { value: 'lt', name: 'Before' },
  //     { value: 'gt', name: 'After' },
  //   ],
  //   inputType: 'date'
  // },
  {
    value: 'orders_count',
    name: 'Orders Count',
    operators: [
      { value: 'lt', name: 'Less than' },
      { value: 'gt', name: 'Greater than' },
    ],
    inputType: 'number'
  },
  {
    value: 'customer_tag',
    name: 'Tags',
    operators: [
      { value: 'includes', name: 'Includes' },
    ],
    inputType: 'tags'
  },
  {
    value: 'total_spent',
    name: 'Total Spent',
    operators: [
      { value: 'lt', name: 'Less than' },
      { value: 'gt', name: 'Greater than' },
    ],
    inputType: 'number'
  },
  // {
  //   value: 'last_order_date',
  //   name: 'Last Order Date',
  //   operators: [
  //     { value: 'lte', name: 'Less then or equal' },
  //     { value: 'lt', name: 'Less than' },
  //     { value: 'gt', name: 'Greater than' },
  //     { value: 'gte', name: 'Greater than or equal to' },
  //   ],
  //   inputType: 'date'
  // }
]

export const shopifyExitIntentCartOptions = [
  {
    value: 'item_count',
    name: 'Item Count',
    operators: [
      { value: 'lt', name: 'Less than' },
      { value: 'gt', name: 'Greater than' },
    ],
    inputType: 'number'
  },
  {
    value: 'total_price',
    name: 'Cart Total',
    operators: [
      { value: 'lt', name: 'Less than' },
      { value: 'gt', name: 'Greater than' },
    ],
    inputType: 'number'
  },
  // {
  //   value: 'vendors',
  //   name: 'Vendors',
  //   operators: [
  //     { value: 'includes', name: 'Contains' },
  //     { value: 'excludes', name: 'Does Not Contain' },
  //     { value: 'includes_any', name: 'Contains Any' },
  //     { value: 'excludes_any', name: 'Does Not Contain Any' },
  //   ],
  //   inputType: 'tags'
  // }
]

const st = require('striptags');
const htmlEntities = require('html-entities');

export const stripTags = (string = '') => {
  string = st(string);
  string = htmlEntities.decode(string);
  string = _.trim(string);
  return string;
}

export function merge(oldObj, newObj) {
  const keys = Object.keys(newObj);

  keys.forEach((key) => {
    if (oldObj[key]) {
      if (_.isObject(oldObj[key])) {
        newObj[key] = merge(oldObj[key], newObj[key])
      } else {
        newObj[key] = newObj[key] + oldObj[key];        
      }
    }
  });

  return newObj;
}

export const applyTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#2167f5",
    danger: "#F26c57",
  }
});

export const isUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return true;
}

function eatIntegers(string) {
  function remove (string = '') {
    if (!string) { return string; }
    const char = string[0];

    if (char === '.') {
      return string.slice(1);
    }

    if (_.isNaN(parseInt(char)) && char !== '.') {
      return string;
    }

    return remove(string.slice(1));
  }
  return remove(string);
}

export const prepareInsight = (content = '') => {
  let items;
  if (typeof content === 'object') {
    items = content;
  } else {
    content = content.replace(/\n\s*\n/g, '\n');
    items = content.split('\n');
  }
  const validItems = [];

  if (!items) { return; }

  items.forEach((item) => {
    item = _.trim(item);
    item = eatIntegers(item);
    item = _.trim(item);

    if (item.length) {
      validItems.push(item);
    }
  });

  return validItems;
}

export const prepareInsights = (insights = []) => {
  let preparedInsights = [];

  insights.forEach((insight) => {
    const { content } = insight;
    
    const items = prepareInsight(content);
    if (!items || items.length <= 1) {
      return;
    }

    insight.content = items;
    preparedInsights.push(insight);
  });

  return preparedInsights;
}

export const processMetadataForDisplay = (metadata) => {
  if (!metadata) { return {}; }

  if (metadata['First Name'] && metadata['first_name']) {
    delete metadata['First Name'];
  }

  if (metadata['Last Name'] && metadata['last_name']) {
    delete metadata['Last Name'];
  }

  return metadata;
}

export function getSlideTitle(slide, handle, title) {
  if (!slide || !slide.type) { return title; }
  if (slide.type !== 'star-rating') { return title; }

  const star = (<i className="fa fa-star" />);

  if (handle === '1-star') {
    return (<span>{ star }</span>);
  }

  if (handle === '2-stars') {
    return (<span>{ star } { star }</span>);    
  }

  if (handle === '3-stars') {
    return (<span>{ star } { star } { star }</span>);
  }

  if (handle === '4-stars') {
    return (<span>{ star } { star } { star } { star }</span>);
  }

  if (handle === '5-stars') {
    return (<span>{ star } { star } { star } { star } { star }</span>);
  }
}

export function getPoll(polls, pollId) {
  return _.find(polls, ({ _id }) => _id == pollId) || {}
}

export function getManualInsightLimit(account) {
  const { plan } = account;
  let manualInsightsLimit = plan.manualInsightsLimit;
  if (!manualInsightsLimit) {
    manualInsightsLimit = 5;

    if (plan.key === 'lite') {
      manualInsightsLimit = 5;
    }
    if (plan.key === 'basic') {
      manualInsightsLimit = 10;
    }
    if (plan.key === 'standard') {
      manualInsightsLimit = 20;
    }
    if (plan.key === 'plus') {
      manualInsightsLimit = 50;
    }
    if (plan.key === 'pro') {
      manualInsightsLimit = 100;
    }
    if (plan.key === 'enterprise') {
      manualInsightsLimit = 200;
    }
  }
  return manualInsightsLimit;
}

export function generatePageDisplaySettings(props) {
  let settings = {
    style: 'wave',
    backgroundColor: '#f5f7f9',
    foregroundColor: '#212a2f'
  };

  if (props.account && props.account.displaySettings) {
    settings.foregroundColor = tinycolor(props.account.displaySettings.backgroundColor).darken(7).toString();
  }
  if (props.poll && props.poll.displaySettings) {
    settings.foregroundColor = tinycolor(props.poll.displaySettings.backgroundColor).darken(7).toString();
  }

  return settings;
}

export function getShareDomain(account) {
  if (!account || !account.customShareDomain) {
    return 'https://survey.zigpoll.com/';
  }
  if (typeof account.customShareDomain === 'string') {
    return `https://${account.customShareDomain}/`;    
  }
  if (account.customShareDomain.state !== 'complete') {
    return 'https://survey.zigpoll.com/';
  }
  if (!account.customShareDomain.name) {
    return 'https://survey.zigpoll.com/';    
  }
  return `https://${account.customShareDomain.name}/`;
}

export function sortChartDataByAnswers(data, answers) {
  answers = answers.map(({ title, handle }) => title || handle);
  data = _.orderBy(data, ({ name }) => answers.indexOf(name), 'asc');

  return data;
}

export function downloadURI(uri, name) {
  var link = document.createElement('a');
  link.download = name;
  link.href = uri;
  link.click();
}

export function getPollType(poll) {
  if (!poll || !poll.settings) { return null; }

  let isEmail = null;
  let isSms = null;
  if (poll.settings.shopifyWebhooks || poll.settings.shopifyAbandonedCheckout) {
    isEmail = true;
    if (poll.settings.sms) {
      isSms = true;
    }
  }

  let pollType = <i className="fas fa-poll" title="This an on-site type survey." />
  if (poll.settings.selector) {
    pollType = <i className="fas fa-arrows-to-dot" title="This an embedded survey." />
  }
  const template = getTemplateFromDisplayRules(poll.pageRules, poll.pageExcludeRules, true);
  if (template) {
    pollType = <i className={`fas ${template.icon}`} title={template.title} />
  }
  if (poll.settings.apiOnly) {
    pollType = <i className="fas fa-link" title="This survey is only visiable via a link." />
  }
  if (poll.settings.apiOnly && poll.settings.pollType === 'api') {
    pollType = <i className="fas fa-code" title="This survey is only visiable via a link and an API call." />
  }
  if (isEmail) {
    pollType = <i className="fas fa-envelope" title="This survey is sent via email." />
    if (isSms) {
      pollType = <i className="fas fa-sms" title="This survey is sent via SMS." />
    }
  }

  return pollType;
}

export function loadContactUsSurvey(user = {}) {
  window.Zigpoll.user = {
    id: user._id,
    metadata: {
      email: user.email,
      name: user.name,
      shop: user.shop
    }
  }
  window.Zigpoll.metadata = {
    email: user.email,
    name: user.name,
    shop: user.shop
  }
  window.Zigpoll.templateVariables = {
    email: user.email
  }

  const pollId = '66930eb46cab8e9c0e7c13f1';
  window.Zigpoll.pollId = pollId;
  if (window.Zigpoll && window.Zigpoll.refresh) {
    window.Zigpoll.refresh();
  }
}

export function getRecipients(recipients, account = {}) {
  if (recipients && recipients.length) {
    return recipients;
  }
  if (account.recipients && account.recipients.length) {
    return account.recipients;
  }
  return [account.ownerEmail];
}

export function getPollDisplayType(poll = {}) {
  let pollType = 'widget';
  if (poll.settings && poll.settings.selector) {
    pollType = 'embed';
  }
  if (poll.settings && poll.settings.apiOnly) {
    pollType = 'link';
  }
  if (poll.settings && poll.settings.shopifyAbandonedCheckout) {
    pollType = 'abandoned-checkout';
  }
  if (poll.settings && poll.settings.shopifyAbandonedCheckout) {
    pollType = 'email';
  }
  if (poll.settings && poll.settings.shopifyWebhooks) {
    pollType = 'email';
  }
  if (poll.settings && poll.settings.sms) {
    pollType = 'sms';
  }
  if (poll.settings && poll.settings.pollType === 'api') {
    pollType = 'api';
  }
  return pollType;
}

export function csvEscape (term) {
  if (term.match && term.match(/,|"/))  {
    return `"${term.replace(/"/g,'""')}"`
  } else {
    return term
  }
}

export function cleanStoreCreditValues(values = []) {
  let storeCreditValues = _.filter(values, ({ value }) => value > 0);
  storeCreditValues = _.uniqBy(storeCreditValues, 'currencyCode');
  return storeCreditValues;
}

export function buildShareLink(account, poll) {
  if (!account || !poll) { return ''; }
  let link = `${getShareDomain(account)}${encode(account._id)}/${encode(poll._id)}`;
  return link;
}
